<template>
    <div class="content">
        <div class="header">
            <div class="data-title">
                <div class="cot"></div>
                <h3>个人设置</h3>
            </div>
            <User></User>
        </div>
        <div class="cont-box">
            <div>
                <div class="user-boximg">
                    <img class="userImg" :src="data.avatar" alt="">
                    <img class="userEdit" src="../../assets/my/edit.png" alt="">
                </div>
                <div class="user-name">
                    <h4>{{ data.nickname }}</h4>
                    <!-- <span @click="navTo('/edit')">编辑</span> -->
                    <button @click="navTo('/edit')">编辑</button>
                </div>
            </div>
            <div class="user-platform">
                <p>签约平台：</p>
                <span v-for="(item, index) in platform " :key="item.id">{{ item.name }}</span>

            </div>
            <div class="user-btn">
                <button class="user-password" @click="dialogFormVisible = true">修改密码 <i
                        class="el-icon-right"></i></button>
                <!-- 修改弹出层 -->
                <el-dialog title="修改密码" :visible.sync="dialogFormVisible">
                    <el-form>
                        <div style="width:500px;margin:0 auto;">
                            <el-form-item label="旧密码" label-width="100px">
                                <el-input type="text" placeholder="请输入旧密码" clearable v-model="bankCard"
                                    oninput="value=value.replace(/[\u4E00-\u9FA5]/g,'')"></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" label-width="100px">
                                <el-input type="text" placeholder="请输入新密码" clearable v-model="newCard"
                                    oninput="value=value.replace(/[\u4E00-\u9FA5]/g,'')"></el-input>
                            </el-form-item>
                        </div>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogFormVisible = false">取 消</el-button>
                        <el-button type="primary" @click="postForgetPassword()">确 定</el-button>
                    </div>
                </el-dialog>
                <button class="user-signOut" @click="dialogVisible = true">退出登陆</button>

            </div>
        </div>
        <!-- 退出提示框 -->
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
            <span>确定退出吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="myUserOut">确 定</el-button>
            </span>
        </el-dialog>
    </div>

</template>
<script>
import { Message } from "element-ui";
import User from "./../../components/user.vue"
export default {
    components: {
        User
    },
    data() {
        return {
            data: {},
            platform: {},
            dialogFormVisible: false,//修改密码的boolean
            dialogVisible: false,//退出提示框的show
            bankCard: '',//旧密码
            newCard: '',//新密码
        }
    },
    created() {
        this.PostUser();//获取用户信息
    },
    methods: {
        //用户信息
        PostUser() {
            let userID = localStorage.getItem('userID');
            this.$api.user({
                userId: userID
            }).then(res => {
                this.data = res.data;
                this.platform = res.plate
            })
        },
        // 修改密码
        postForgetPassword() {
            let bankCardLen = this.bankCard.length;
            let newCardLen = this.newCard.length;
            console.log(bankCardLen, newCardLen);
            if (bankCardLen >= 6 && bankCardLen <= 18 && newCardLen >= 6 && newCardLen <= 18) {
                let userID = localStorage.getItem('userID')
                this.$api.ForgetPassword({
                    oldPassword: this.bankCard,
                    nowPassword: this.newCard,
                }).then(res => {
                    Message.success(res.message);
                    this.dialogFormVisible = false;
                })
            } else {
                Message.error('请输入6～18位密码')
            }


        },
        // 退出
        myUserOut() {
            localStorage.clear()
            this.$router.push('/')
        },
    }
}
</script>
<style scoped>
.content {

    min-height: 100vh;
    background-color: #eee;
}

.header {
    width: 90%;
    margin: 0 auto;
    padding-top: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cont-box {
    width: 90%;
    margin: 0 auto;
    margin-top: 2em;
    padding: 2em;
    background-color: #fff;
    box-sizing: border-box;
    /* text-align: center; */
    padding: 20vh 0;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-boximg {
    width: 7em;
    /* margin: 0 auto; */
    position: relative;
}

.userImg {
    width: 7em;
    height: 7em;
    border: 5px solid #4f7dff;
    border-radius: 50%;
}

.userEdit {
    bottom: 0;
    right: 0;
    position: absolute;
}

.user-name {
    margin-top: 2em;
    display: flex;
    align-items: center;
}

.user-name h4 {
    font-size: 2em;
    color: #333;
}

.user-name button {
    background-color: #fff;
    border: none;
    margin-left: 1em;
    color: #999;
}

.user-platform {
    margin-top: 2em;
    color: #666666;
    display: flex;
    align-items: center;
}

.user-platform span {
    padding: 0 5px;
}

.user-btn {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
}

.user-btn button {
    width: 22em;
    line-height: 3em;
}

.user-password {
    border: 1px solid #4f7dff;
    background-color: #eef1fb;
    color: #4f7dff;
    margin-bottom: 1em;
}

.user-signOut {
    border: none;
    background-color: #5171ff;
    color: #fff;
}
</style>